// import { isMobile } from '@/utils'

export default {
  address: '新北市五股區新城六路159號',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7226.616800755305!2d121.4409764!3d25.0914197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a8aa1b8531e3%3A0xa9a4897f5a8af782!2zMjQ45paw5YyX5biC5LqU6IKh5Y2A5paw5Z-O5YWt6LevMTU56Jmf!5e0!3m2!1szh-TW!2stw!4v1684718968669!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/wvbTBjapoEN21LTJ8',
  phone: '02-82951777',
  fbLink:
    'https://www.facebook.com/神采飛洋-101802774887170',
  fbMessage: 'https://m.me/101802774887170',
  caseName: '神采飛洋',
  houseInfos: [
    ['投資興建', '茂德建設股份有限公司'],
    ['建築設計', '陳朝雄 A8建築師事務所'],
    ['結構工程', '吳志強 永安結構顧問'],
    ['門廳公設', '徐慈姿 珩荷空間設計'],
    ['園藝景觀', '徐世萃 大漢設計工程'],
    ['格局規劃', '二房(15、20坪) 、 三房(25、33坪)'],
    ['公設比例', '32.5%'],
    ['樓層規劃', '地上15層，地下6層'],
    ['基地面積', '846.66坪'],
    ['使照號碼', '110股使字第00166號'],
  ],
  gtmCode: ['PS8ZD97'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  },
}
